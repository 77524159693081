(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {

        checkUlozenkaBranches();

        selector.on("change", "[data-toggle] input", function () {
            let row = $(this).closest("[data-toggle]").next(".elm_form_row");
            if ($(this).prop("checked") === true) {
                row.slideDown(300);
            } else {
                row.slideUp(300);
            }
        });

        //data-add-voucher
        $(document).on('keypress',function(e) {
            if(e.which === 13) {
                if($('[data-voucher-code]').is(':focus')) {
                    e.preventDefault();
                    $('[data-add-voucher]').trigger('click');
                }
            }
        });

        $(document).on('change', '[data-change-country]', function () {
            var elm = $(this);

            $.ajax({
                url: elm.data('change-country'),
                method: 'POST',
                data: {countryId: elm.val()}
            }).done(function () {
                window.location.href = location.href;
            });
        });

        selector.on('change','input[type="number"][data-ajax-link]',function() {
            var ajaxLink = $(this).attr('data-ajax-link');
            var count = $(this).val();

            $.ajax({
                url: ajaxLink,
                data: {count: count},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        selector.on('click','[data-add-voucher]',function() {

            let url = $(this).attr('data-ajax-link');
            let code = $('input[data-voucher-code]').val();

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                cache: false,
                data: {
                    code: code
                }
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });

        });

        selector.on('click', '[data-show-cdek]', function () {
            $('[data-dialog-cdek]').click();
        });

        window.lui[name] = comp;
    }
})("comp_package_basket");