(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {

        console.log('init product head');

        let carousel = selector.find("[data-slider]");

        $.lib_flickity(function(){
            if (carousel.length) {
                carousel.flickity({
                    groupCells: '25%',
                    cellAlign: 'left',
                    contain: true,
                    pageDots: false,
                    wrapAround: true,
                    prevNextButtons: true,
                    setGallerySize: true
                });
            }
        });

        selector.on('change','[data-product-variant]',function() {

            console.log('trigger change');

            let variantId = $('[data-product-variant]').val();
            let url = $(this).attr('data-ajax-link');

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                data: {
                    variantId: variantId
                },
                cache: false
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();

                });
            });
        });

        window.lui[name] = comp;
    }
})("comp_product_head");