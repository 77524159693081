(function($) {
    if (!html.hasClass("ie")) {
        doc.on("change", ".part_ui_input", function () {
            if ($(this).hasClass("type--number-round")) {
                if ($(this).find("input").val() === "" || parseInt($(this).find("input").val()) > $(this).find("input").attr("max")) {
                    $(this).find("input").val(1);
                }
            }
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validateInput();
            }
        });

        doc.on("click", ".part_ui_input [data-number]", function (e) {
            e.preventDefault();
            let elm = $(this);
            let inp = elm.closest(".part_ui_input").find("input");
            let num;

            if (elm.filter('[data-number="plus"]').length) {
                num = +inp.val() + inp.data('step');
                if (num <= inp.attr('max')) {
                    inp.val(num).trigger("change");
                }
            }
            if (elm.filter('[data-number="minus"]').length) {
                num = +inp.val() - inp.data('step');
                if (num >= inp.attr('min')) {
                    inp.val(num).trigger("change");
                }
            }
        });

        doc.on("keydown", ".part_ui_input.type--number-round input", function(e) {
            // Allow: backspace, delete, tab, escape, enter and .
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 || e.keyCode === 16 || (e.keyCode == 65 && e.ctrlKey === true) || (e.keyCode >= 35 && e.keyCode <= 39)) {
                return;
            }
            // Ensure that it is a number and stop the keypress
            if (((e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });

        $.libInit(".part_ui_input", "lib_ui_input", function(selector) {
            $(selector).each(function () {
                if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("state--invalid")) {
                    $(this).validateInput();
                }
                if($(this).find("[type=\"tel\"]").length) {
                    let input = $(this).find("[type=\"tel\"]");
                    input.closest(selector).addClass("state--focus");
                    $.importScript(cdnjs.intltelinput,function () {
                        var iti = intlTelInput(input[0],{
                            initialCountry: $('HTML').attr('lang'),
                            nationalMode: false,
                            preferredCountries: ['cz', 'sk', 'gb', 'de', 'ru'],
                            separateDialCode: true
                        });
                        var numberCode = iti.getSelectedCountryData();
                        if(typeof numberCode.dialCode === "undefined") {
                            $('[data-infotel]').show();
                        } else {
                            $('#hiddenTel').val('+'+numberCode.dialCode);
                            $('[data-infotel]').hide();
                        }
                    });
                }
            });
        });
        doc.on("click", "#country-listbox", function(e) {
            $('#hiddenTel').val($(this).parent().find('.iti__selected-dial-code').html());
        });
    }
})(jQuery);