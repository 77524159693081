(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {

        selector.on("click",'[data-click="toggleItem"]',function (e) {
            e.preventDefault();
            let elm = $(this).closest("[data-ref-item]"),
                body = elm.find("[data-ref-item-body]");

            body.stop();
            if(elm.hasClass("state--active")) {
                elm.removeClass("state--active");
                body.slideUp();
            }
            else {
                elm.addClass("state--active");
                body.slideDown();
            }
        })

        window.lui[name] = comp;
    }
})("comp_site_faq");