(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let carousel = selector.find("[data-slider]");

        $.lib_flickity(function(){
            if (carousel.length) {
                let wrapAround = false
                let prevNextButtons = false

                if(window.innerWidth > 959) {
                    if (carousel.childElementCount > 3) {
                        wrapAround = true
                        prevNextButtons = true
                    }
                } else if(window.innerWidth > 767) {
                    if(carousel.childElementCount > 2) {
                        wrapAround = true
                        prevNextButtons = true
                    }
                } else {
                    if(carousel.childElementCount > 1) {
                        wrapAround = true
                        prevNextButtons = true
                    }
                }

                carousel.flickity({
                    groupCells: '25%',
                    cellAlign: 'left',
                    contain: true,
                    pageDots: false,
                    wrapAround: wrapAround,
                    prevNextButtons: prevNextButtons
                });

                carousel.addClass("flickity-flex");

                window.addEventListener("resize",debounce(()=> {
                    carousel.removeClass("flickity-flex");
                    carousel.flickity('resize');
                    carousel.addClass("flickity-flex");
                },250));
            }
        });

        window.lui[name] = comp;
    }
})("comp_products_slider_double");
