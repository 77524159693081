(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let carousel = selector.find("[data-slider]");
        let autoplay = carousel.data("slider");

        $.lib_flickity(function(){
            $.importScript(cdnjs.flickity_sync, function() {
                if (carousel.length) {
                    carousel.flickity({
                        imagesLoaded: false,
                        percentPosition: false,
                        setGallerySize: true,
                        autoPlay: autoplay,
                        pageDots: carousel.children().length > 1,
                        wrapAround: true,
                        contain: true,
                        prevNextButtons: carousel.children().length > 1
                    });
                }
            });

        });

        window.lui[name] = comp;
    }
})("comp_products_visual");