(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let carousel = selector.find("[data-slider]");

        $.lib_flickity(function(){
            if (carousel.length) {
                carousel.flickity({
                    groupCells: '25%',
                    cellAlign: 'left',
                    contain: true,
                    pageDots: false,
                    wrapAround: true,
                    prevNextButtons: true,
                    on: {
                        ready: function () {
                            if(carousel.children().length < 4) {
                                carousel.addClass("flickity--disabled")
                            }
                        }
                    }
                });
            }
        });

        window.lui[name] = comp;
    }
})("comp_base_gallery");