(function($) {
    const fn = $("#layout_header");

    if (fn.length) {
        let header = fn.find(".wrp_header_body");

        fn.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

        let layout_nav = $(document).find("#layout_nav"),
            logo = header.find(".elm_header_logo").clone(),
            nav = header.find(".elm_header_nav").clone(),
            nav_eshop = nav.find("[data-products-hover]").after("<ul class=\"elm_products\"></ul>");

        fn.find(".wrp_header_products").find(".elm_item").each(function () {
           let el = $(this);
           let elm = "<a href=\"" + el.attr("href") +"\" class=\"part_ui_link\"><span>"+ el.find(".part_ui_title").text() +"</span></a>";

           nav_eshop.next("ul").append("<li>" + elm + "</li>");
        });

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(nav);

        let timeout;

        // doc.on({
        //     "mouseenter": function () {
        //         if (win.width() > 960) {
        //             if (fn.find(".wrp_header_products").css("display") === "none") {
        //                 clearTimeout(timeout);
        //                 fn.find(".wrp_header_products").slideDown(300);
        //                 fn.addClass("is--hovered");
        //             }
        //         }
        //     },
        //     "mouseleave": function (e) {
        //         if (win.width() > 960) {
        //             if (!$(e.relatedTarget).hasClass("wrp_header_body")) {
        //                 fn.find(".wrp_header_products").slideUp(300);
        //                 timeout = setTimeout(function () {
        //                     fn.removeClass("is--hovered");
        //                 }, 150);
        //             }
        //         }
        //     }
        // }, "[data-products-hover]");
        //
        // doc.on("mouseleave", ".wrp_header_products", function(e){
        //     if (!$(e.relatedTarget).hasClass("wrp_header_body")) {
        //         fn.find(".wrp_header_products").slideUp(300);
        //         timeout = setTimeout(function(){
        //             fn.removeClass("is--hovered");
        //         },150);
        //     }
        // });

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').slideAndSwipe();
        });

        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom(fn[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });
    }
})(jQuery);